import { Input, InputProps } from '@nextui-org/input'
import { forwardRef } from 'react'

import { cn } from '@/utils'

import { getSize } from '../utils'

export interface UIInputProps extends Omit<InputProps, 'size'> {
  size?: InputProps['size'] | 'xs'
}

const onFocusRingClassName = [
  'group-data-[focus=true]:ring-1',
  'group-data-[focus=true]:ring-focus',
  'group-data-[invalid=true]:group-data-[focus=true]:ring-danger',
  'group-data-[focus-visible=true]:ring-1',
  'group-data-[focus-visible=true]:ring-focus',
  'group-data-[focus-visible=true]:ring-offset-0',
]

const outlineClassName = [
  'group-data-[focus-visible=true]:outline-1',
  'group-data-[focus-visible=true]:outline-focus',
  'group-data-[focus-visible=true]:outline-offset-0',
]

const UIInput = forwardRef<HTMLInputElement | null, UIInputProps>(
  ({ size = 'sm', ...props }: UIInputProps, ref) => {
    return (
      <Input
        ref={ref}
        variant="flat"
        {...props}
        size={getSize<InputProps>(size)}
        classNames={{
          ...props.classNames,
          input: cn([
            'ui caret-primary',
            'placeholder:text-foreground-400',
            'group-data-[invalid=true]:caret-danger',
            size === 'xs' && 'text-tinyPlus',
            props.classNames?.input,
          ]),
          inputWrapper: cn(
            'px-3',
            ...(props.variant === 'bordered'
              ? ['group-data-[focus=true]:border-focus']
              : [...outlineClassName, ...onFocusRingClassName]),
            size === 'xs' && 'dense-detail-value-min-h h-[30px]',
            props.classNames?.inputWrapper,
          ),
        }}
      />
    )
  },
)

UIInput.displayName = 'UIInput'

export default UIInput
