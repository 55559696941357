import {
  HydrationBoundary,
  MutationCache,
  QueryCache,
  QueryClient,
  QueryClientProvider,
} from '@tanstack/react-query'
import React, { PropsWithChildren, useState } from 'react'

import { useToast } from '@/components/ui'
import { useLogOut } from '@/hooks'
import { unknownGlobalErrorMessage } from '@/constants'
import { getParsedError } from '@/utils'
import { ReactQueryOrMutationMeta } from '@/models'

import { queryClientDefaultOptions } from './utils'

const UNAUTHORIZED_STATUS_CODE_MAP = {
  401: 'Unauthorized - Expired session or invalid token',
  404: 'Not found - User in token does not exist',
}

const ReactQueryProvider = ({
  dehydratedState,
  children,
}: PropsWithChildren<{ dehydratedState: unknown }>) => {
  const { toast } = useToast()

  const logOut = useLogOut()

  const [queryClient] = useState(
    () =>
      new QueryClient({
        queryCache: new QueryCache({
          onError: (error, query) => onErrorHandler(error, query.options.meta),
        }),
        mutationCache: new MutationCache({
          onError: (error, _, __, mutation) => {
            onErrorHandler(error, mutation.options.meta)
            return
          },
        }),
        defaultOptions: queryClientDefaultOptions,
      }),
  )

  const onErrorHandler = (
    rawError: unknown,
    meta?: ReactQueryOrMutationMeta,
  ) => {
    const { responseStatus, knownError } = getParsedError(rawError)

    if (
      !knownError &&
      responseStatus &&
      Object.keys(UNAUTHORIZED_STATUS_CODE_MAP).includes(
        responseStatus.toString(),
      )
    ) {
      console.error(
        UNAUTHORIZED_STATUS_CODE_MAP[responseStatus.toString() as never],
      )

      logOut()
      return
    }

    const { throwKnownErrorAsGlobal = true } = meta || {}
    if (knownError) {
      throwKnownErrorAsGlobal && toast.error(knownError.detail)
      return
    }

    console.error('Error found', rawError)

    // TODO: known errors from query hooks, remove this once all api hooks has been refactored to the new standard
    // This should be handled by using a standard error obj/structure from the API.
    // eg code, not injecting metadata in each react query or mutation
    const knownLocalError = meta?.error
    if (typeof knownLocalError === 'string') {
      toast.error(knownLocalError)
      return
    }

    // TODO: Add logging to third party service using the whole parsed error object
    toast.error('Error', { description: unknownGlobalErrorMessage })
  }

  return (
    <QueryClientProvider client={queryClient}>
      <HydrationBoundary state={dehydratedState}>{children}</HydrationBoundary>
    </QueryClientProvider>
  )
}

export default ReactQueryProvider
