import {
  Dropdown,
  DropdownItem,
  DropdownMenuProps,
  DropdownProps,
  DropdownSection,
  DropdownSectionProps,
  DropdownTrigger,
  DropdownMenu,
} from '@nextui-org/dropdown'
import { MenuItemProps } from '@nextui-org/menu/dist/menu-item'
import { CollectionChildren } from '@react-types/shared/src/collections'
import { useMemo } from 'react'

export type UIItemConfig<K extends object = { key: string }> = Omit<
  MenuItemProps<K>,
  'item' | 'state'
>

export type UISectionConfig<K extends object = { key: string }> = Omit<
  DropdownSectionProps<K>,
  'children'
> & {
  items: UIItemConfig<K>[]
}

export type UIDropdownProps<K extends object = object> = Omit<
  DropdownMenuProps<K>,
  'children'
> & {
  items?: UIItemConfig<K>[]
  sections?: UISectionConfig<K>[]
  dropdownProps?: Omit<DropdownProps, 'children'>
  triggerContent?: JSX.Element
}

const UIDropdown = <K extends UIItemConfig<K>>({
  items,
  sections,
  dropdownProps,
  triggerContent,
  ...dropdownMenu
}: UIDropdownProps<K>) => {
  const {
    base: customBaseClasses,
    title: customTitleClasses,
    ...itemClasses
  } = dropdownMenu.itemClasses || {}

  const children: CollectionChildren<K> | null = useMemo(() => {
    if (sections) {
      return sections.map(({ items, ...section }) => (
        <DropdownSection
          key={section.key}
          {...section}
          classNames={{ base: 'last-of-type:mb-0', ...section.classNames }}
        >
          {items.map(({ key, ...item }) => (
            <DropdownItem key={key} {...item} />
          ))}
        </DropdownSection>
      ))
    }

    if (items) {
      return items.map(({ key, ...item }) => (
        <DropdownItem key={key} {...item} />
      ))
    }

    return null
  }, [sections, items])

  if (!children) return null

  return (
    <Dropdown
      {...dropdownProps}
      radius="sm"
      classNames={{
        ...dropdownProps?.classNames,
        content: [
          'bg-background dark:shadow-medium dark:border-[0.5px] dark:border-divider/10',
          dropdownProps?.classNames?.content,
        ],
      }}
    >
      {triggerContent && <DropdownTrigger>{triggerContent}</DropdownTrigger>}

      <DropdownMenu
        {...dropdownMenu}
        variant="flat"
        itemClasses={{
          base: [
            'transition-opacity dark:data-[hover=true]:bg-default/50 dark:data-[selectable=true]:focus:bg-default/50',
            'data-[focus-visible=true]:z-0 data-[focus-visible=true]:outline-0 data-[focus-visible=true]:outline-none data-[focus-visible=true]:outline-offset-0 data-[focus-visible=true]:dark:ring-offset-background-content1',
            ...Array.from(customBaseClasses || []),
          ],
          title: ['text-xs', ...Array.from(customTitleClasses || [])],
          description: ['group-hover:text-default-500'],
          ...itemClasses,
        }}
      >
        {children}
      </DropdownMenu>
    </Dropdown>
  )
}

UIDropdown.displayName = 'UIDropdown'

export default UIDropdown
