import { useMediaQuery } from 'usehooks-ts'

import { MOBILE_MEDIA_QUERY } from '@/constants'

export function useResolution() {
  const isMobile = useMediaQuery(MOBILE_MEDIA_QUERY, {
    initializeWithValue: false,
  })

  const isMidResolution = useMediaQuery('(max-width: 1024px)', {
    initializeWithValue: false,
  })

  return {
    isMobile,
    isMidResolution,
  }
}
