type InputSizeType = {
  size?: 'sm' | 'md' | 'lg'
}

export function getSize<T extends InputSizeType = InputSizeType>(
  size: T['size'] | 'xs',
) {
  if (!size) return undefined

  return size === 'xs' ? ('sm' as T['size']) : (size as T['size'])
}
