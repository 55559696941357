import { useAtom } from 'jotai'
import { useEffect, useMemo } from 'react'

import {
  globalStore,
  isSidebarCollapsedAtom,
  isSidebarHiddenAtom,
} from '@/services/state/global'

import { useResolution } from './useResolution'

export const useSidebar = () => {
  const { isMidResolution, isMobile } = useResolution()

  // TODO: Using specific store because we are using the global store with ssr,
  // some childs loose the ref to the correct store
  const [isSidebarCollapsed, setIsSidebarCollapsed] = useAtom(
    isSidebarCollapsedAtom,
    {
      store: globalStore,
    },
  )

  const [isSidebarHidden, setIsSidebarHidden] = useAtom(isSidebarHiddenAtom, {
    store: globalStore,
  })

  const isCompact = useMemo<boolean>(
    () => !isMidResolution && isSidebarCollapsed,
    [isMidResolution, isSidebarCollapsed],
  )

  const isHidden = useMemo<boolean>(
    () => (isMidResolution || isMobile) && isSidebarHidden,
    [isMidResolution, isSidebarHidden, isMobile],
  )

  useEffect(() => {
    if (isMidResolution) setIsSidebarHidden(true)
  }, [isMidResolution, setIsSidebarHidden])

  return {
    isCompact,
    isHidden,
    canHide: isMidResolution,
    isSidebarCollapsed,
    isSidebarHidden,
    setIsSidebarCollapsed,
    setIsSidebarHidden,
  }
}
